.wrapper {
	width: 100%;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.wrapper {
		margin-right: auto;
		margin-left: auto;
		max-width: 1024px;
	}
}

.wrapper-text-block {
	padding-right: 20px;
	padding-left: 20px;
}

.wrapper-error {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.wrapper-error {
		margin-right: auto;
		margin-left: auto;
		max-width: 1024px;
	}
}
