.site-header__container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.site-header__link {
	margin-right: auto;
	margin-left: auto;
}

.site-header__link svg {
	max-width: 135px;
}
