.carousel {
	display: block;
	overflow: hidden;
	position: relative;
	width: 100%;
}

/* #region Buttons */
.carousel__previous-button,
.carousel__next-button {
	appearance: none;
	background: none;
	background-color: var(--color-black);
	border: 0;
	border-radius: 0;
	box-shadow: none;
	color: var(--color-white);
	cursor: pointer;
	outline: 0;
	padding: 0;
	position: absolute;
	bottom: 0;
	width: 1.875rem;
	height: 1.875rem;
	z-index: 2;
}

.carousel__previous-button {
	left: 50%;
	transform: rotate(180deg);
}

.carousel__next-button {
	right: 0;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.carousel__previous-button,
	.carousel__next-button {
		width: 2.5rem;
		height: 2.5rem;
	}
}

/* Arrows. */
.carousel__previous-button::before,
.carousel__next-button::before {
	background-color: var(--color-black);
	background-image: url("../images/arrow.svg");
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: 1.125rem 0.8125rem;
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 1.875rem;
	height: 1.875rem;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.carousel__previous-button::before,
	.carousel__next-button::before {
		background-size: 1.5rem 1.0625rem;
		width: 2.5rem;
		height: 2.5rem;
	}
}

/* States. */
.carousel__previous-button:focus,
.carousel__next-button:focus {
	box-shadow: 0 0 0.625rem 0 var(--color-red);
}

.carousel__previous-button:focus:not(:focus-visible) {
	box-shadow: none;
}

.carousel__next-button:focus:not(:focus-visible) {
	box-shadow: none;
}

.carousel__previous-button:focus-visible {
	box-shadow: 0 0 0.625rem 0 var(--color-red);
}

.carousel__next-button:focus-visible {
	box-shadow: 0 0 0.625rem 0 var(--color-red);
}
/* #endregion Buttons */

/* #region Items */
.carousel__items {
	aspect-ratio: 16 / 9;
	width: 100%;
	z-index: 1;
}
/* #endregion Item */

/* #region Item */
.carousel__item {
	display: none;
	font-size: 0;
	margin: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	transition: opacity 128ms linear;
	width: 100%;
	height: 100%;
}

.carousel__item[data-carousel-item-current],
.carousel__item[data-carousel-item-next],
.carousel__item[data-carousel-item-previous] {
	display: block;
}

.carousel__item[data-carousel-item-current] {
	opacity: 1;
	z-index: 2;
}

.carousel__item[data-carousel-item-next],
.carousel__item[data-carousel-item-previous] {
	z-index: 1;
}
/* #endregion Item */

/* #region Image */
.carousel__image {
	width: 100%;
	height: auto;
}
/* #endregion Image */
