.theme-red {
	background-color: var(--color-red);
	color: var(--color-white);
}

.theme-grey {
	background-color: var(--color-light-grey);
	color: var(--color-dark-grey);
}

.theme-white {
	background-color: var(--color-white);
	color: var(--color-dark-grey);
}
