@media (prefers-reduced-motion: reduce) {
	*,
	*::after,
	*::before {
		animation-delay: -1ms !important;
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		background-attachment: initial !important;
		transition-delay: -1ms !important;
		transition-duration: 0.01ms !important;
	}
}

body {
	background-color: var(--color-background);
	box-sizing: border-box;
	color: var(--color-foreground);
	position: relative;
	min-height: 100vh;
}

textarea {
	resize: none;
}

input,
button,
textarea,
select {
	font: inherit;
}

hr {
	background-color: currentColor;
	border: none;
	height: 1px;
}

img,
video {
	display: block;
	max-width: 100%;
	height: auto;
}

table,
th,
td {
	border: 1px solid currentColor;
}

table {
	border-collapse: collapse;
	box-sizing: border-box;
	margin: 2rem 0;
	width: 100%;
	max-width: 100%;
}

table:first-child {
	margin-top: 0;
}

table:last-child {
	margin-bottom: 0;
}

th,
td {
	box-sizing: border-box;
	padding: 2rem;
	text-align: left;
}

ol:not([class]),
ul:not([class]) {
	box-sizing: border-box;
	margin: 0 0 2rem 0;
}

ol:not([class]) ol:not([class]),
ol:not([class]) ul:not([class]),
ul:not([class]) ol:not([class]),
ul:not([class]) ul:not([class]) {
	padding-left: 2rem;
}

ol:not([class]):last-child,
ul:not([class]):last-child {
	margin-bottom: 0;
}

li ol:not([class]),
li ul:not([class]) {
	margin-bottom: 0;
}

ul:not([class]) {
	list-style: none;
	padding-left: 0;
}

ol:not([class]) {
	padding-left: 1.25rem;
}

li:not([class]) {
	margin: 0;
	padding: 0;
	position: relative;
}

ul:not([class]) li:not([class])::before {
	content: "• ";
}

a:not([class]) {
	color: var(--color-red);
	display: inline;
	text-decoration: underline;
}

a:not([class]):active,
a:not([class]):focus,
a:not([class]):hover {
	text-decoration: none;
}

.theme-red a:not([class]) {
	color: var(--color-white);
}

p a:not([class]) {
	line-height: inherit;
}
