.row-text-block {
	padding-top: 20px;
	padding-bottom: 20px;
	width: 100%;
}

.row-block-50 {
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
}

/* from-medium,tablet-l */
@media (min-width: 768px) {
	.row-block-50 {
		grid-template-rows: 1fr;
		grid-template-columns: 1fr 1fr;
	}
}
