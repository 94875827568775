:root {
	/* #region Colors */
	--color-foreground: #000000;
	--color-background: #ffffff;
	--color-black: #000000;
	--color-dark-grey: #77787b;
	--color-light-grey: #f2f2f2;
	--color-red: #ff292f;
	--color-white: #ffffff;

	/* #endregion Colors */

	/* #region Typography */
	--font-family: sofia-pro, sans-serif;
	/* #endregion Typography */

	/* #region spaces */
	--space-small: 1.25rem;
	/* #endregion Typography */
}
