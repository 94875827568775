* {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--font-family);
}

body {
	font-family: var(--font-family);
	font-size: 0.875rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.57;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	body {
		font-family: var(--font-family);
		font-size: 1rem;
		line-height: normal;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-family: var(--font-family);
	margin-top: 0.875rem;
	margin-bottom: 0.875rem;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
	margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
	margin-bottom: 0;
}

h1:only-child,
h2:only-child,
h3:only-child,
h4:only-child,
h5:only-child,
h6:only-child {
	margin-top: 0;
	margin-bottom: 0;
}

i,
em,
blockquote {
	font-style: italic;
}

/* #region Type classes */
/**
 * Names used in Sketch design files:
 * - Desktop/TitleLarge
 * - Mobile/TitleLarge
 */
h1:not([class*="type-"]),
h2:not([class*="type-"]),
.type-a {
	font-family: var(--font-family);
	font-size: 1.5rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 600;
	letter-spacing: normal;
	line-height: 1.4;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	h1:not([class*="type-"]),
	h2:not([class*="type-"]),
	.type-a {
		font-family: var(--font-family);
		font-size: 2.25rem;
		line-height: 1.4;
	}
}

/**
 * Names used in Sketch design files:
 * - Desktop/Title
 * - Mobile/Title
 */
h3:not([class*="type-"]),
h4:not([class*="type-"]),
h5:not([class*="type-"]),
h6:not([class*="type-"]),
.type-b {
	font-family: var(--font-family);
	font-size: 1.375rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 600;
	letter-spacing: normal;
	line-height: 1.4;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	h3:not([class*="type-"]),
	h4:not([class*="type-"]),
	h5:not([class*="type-"]),
	h6:not([class*="type-"]),
	.type-b {
		font-family: var(--font-family);
		font-size: 1.625rem;
		line-height: normal;
	}
}

/**
 * Names used in Sketch design files:
 * - Desktop/Body
 * - Mobile/Body
 */
p:not([class*="type-"]),
.type-c {
	font-family: var(--font-family);
	font-size: 0.875rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.37;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	p:not([class*="type-"]),
	.type-c {
		font-family: var(--font-family);
		font-size: 1rem;
		line-height: 1.5;
	}
}
